import fetchTaskStatus from '~/utils/fetch-task-status'

export const useStatusStore = defineStore(
  'status',
  () => {
    const status = {
      running: 'RUNNING',
      idle: 'IDLE',
      success: 'SUCCESS',
      error: 'ERROR',
    }
    const { setPopup } = usePopup()
    const taskId = ref<string>('')
    const taskStatus = ref<string>(status.idle)
    const isTaskDeleted = ref<boolean>(false)
    const progressTime = ref<number>(0)
    const progressCurrentImage = ref<string>('')
    const progressWidth = ref(0)
    const count = ref<number>(0)
    const isGenerating = ref<boolean>(false)
    const orderOfRendering = ref<number>(0)

    const setLoading = (bool: boolean) => {
      isGenerating.value = bool
    }

    const getTaskId = async () => {
      const { data } = await useCustomFetch('/api/users/tasks')
      taskId.value = data.value
    }

    const setTaskId = async (id: string | null = null) => {
      const response = await useCustomFetch('/api/users/tasks/set', {
        method: 'POST',
        body: { taskId: id },
      })
      taskId.value = response.data.value
    }

    const unSetTaskId = async () => {
      await setTaskId()
      taskId.value = ''
    }

    const setIsDeleted = (bool: boolean) => {
      isTaskDeleted.value = bool
    }

    const unSetTaskStatus = () => {
      taskStatus.value = status.idle
    }

    const unSetProgressStatus = () => {
      progressCurrentImage.value = ''
      progressTime.value = 0
    }

    const deleteTask = async () => {
      // delete task here
      const deletedTask = await useCustomFetch('/api/tasks', {
        method: 'DELETE',
        body: { id: taskId },
      })

      if (deletedTask.status && deletedTask.status.value) {
        setIsDeleted(true)
        unSetProgressStatus()
        unSetTaskStatus()
      } else {
        console.error('レンダリングの中止に失敗しました。')
      }
    }

    const checkCurrentTaskStatus = async () => {
      taskStatus.value = status.running
      let response
      while (taskStatus.value === status.running) {
        // checking render progress status
        // const { data } = await useCustomFetch("/api/artifacts/generates/progress");
        // if (data.value) {
        //   setProgress(data.value);
        //   // console.log(
        //   //   "checkCurrentRenderProgress LOOP PROGRESS: ",
        //   //   data?.value
        //   // );
        // }

        await new Promise(resolve => setTimeout(resolve, 5000))
        response = await fetchTaskStatus(taskId.value)
        taskStatus.value = response?.statusMessage
        if (response.status === 200 && orderOfRendering.value !== response.message) {
          orderOfRendering.value = Number(response.message)
          setPopup(`リクエストを順に処理中です（現在の順番: ${orderOfRendering.value} 番目）`, 'primary-light')
        }
      }

      if (taskStatus.value === status.error) {
        setPopup(response.message, 'red')
        unSetTaskStatus()
        unSetProgressStatus()
        setLoading(false)
        orderOfRendering.value = 0
      }
      if (taskStatus.value === status.success) {
        unSetTaskId()
        orderOfRendering.value = 0
      }
    }

    const resetProgressBarAndCount = () => {
      progressWidth.value = 0
      count.value = 0
    }

    return {
      isGenerating,
      orderOfRendering,
      taskId,
      taskStatus,
      isTaskDeleted,
      progressCurrentImage,
      count,
      progressWidth,
      progressTime,
      getTaskId,
      setTaskId,
      unSetTaskId,
      unSetTaskStatus,
      checkCurrentTaskStatus,
      unSetProgressStatus,
      deleteTask,
      setIsDeleted,
      setLoading,
      resetProgressBarAndCount,
    }
  },

  {
    persist: true,
  },
)
